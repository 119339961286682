import { useEffect } from 'react';
import { useAfterAuthRedirect } from '~/app/auth/_hooks/use-after-auth-redirect';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { getFirstQuery } from '~/utils/url';

type ColorMode = 'light' | 'dark';

export const useLocalHooks = (searchParams: {
  [key: string]: string | string[] | undefined;
}) => {
  const { session } = useTellerSession();
  const { onBeforeLogin } = useAfterAuthRedirect();
  const encodedFrom = getFirstQuery(searchParams['from']);
  const encodedFromService = getFirstQuery(searchParams['fromService']);
  const from =
    typeof encodedFrom === 'string'
      ? decodeURIComponent(encodedFrom)
      : undefined;

  const fromService =
    typeof encodedFromService === 'string'
      ? decodeURIComponent(encodedFromService)
      : undefined;

  const colorMode: ColorMode =
    getFirstQuery(searchParams['colorMode']) === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    onBeforeLogin();
  }, [onBeforeLogin]);

  return {
    noLayout: searchParams['nolayout'] === '1',
    isAnonymous: session?.isAnonymous,
    from,
    fromService,
    colorMode,
  };
};
