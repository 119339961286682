'use client';
import { Suspense } from 'react';
import { Loading } from '~/app/_components/loading';
import { routes } from '~/utils/routes';

import { Link } from '@app/_components/link';
import appleIcon from '@app/auth/_assets/apple_icon.png';
import googleIcon from '@app/auth/_assets/google_icon.png';
import { AuthButton } from '@app/auth/_components/auth-button';
import { Providers } from '@app/auth/_constants/providers';
import { EmailFieldContainer } from '@app/auth/_containers/email-field-container';
import {
  styled,
  Paragraph,
  Box,
  VStack,
  Center,
  HStack,
} from '@styled-system/jsx';
import { useLocalHooks } from './_hooks/use-local-hooks';

type Props = {
  searchParams: { [key: string]: string | string[] | undefined };
};

export default function Page({ searchParams }: Props) {
  const { noLayout, isAnonymous, from, fromService, colorMode } =
    useLocalHooks(searchParams);

  return (
    <Center
      w="100%"
      h={{ base: '100%', lg: 'calc(100vh - 172px)' }} // for PC, center vertically
      minHeight={{ base: 'auto', lg: '665px' }}
      lg={{
        pt: '40px',
        pb: '40px',
      }}
      alignItems="baseline"
    >
      {/* If login is requested from studio, wait for session to be checked before showing login options.
          If user is already logged in, issue auth token and redirect back to studio without showing anything more
          than loading animation
      */}
      {noLayout && !isAnonymous ? (
        <Loading p="50px" />
      ) : (
        <VStack
          width={{ lg: '440px', lgDown: '100%' }}
          height={{ lg: 'auto', lgDown: 'calc(100vh - 50px)' }}
          textAlign="center"
          backgroundColor="surface_primary"
          padding={{ lg: '40px 40px 160px 40px', lgDown: '40px 20px' }}
          borderRadius="8px"
          position="relative"
        >
          <Paragraph fontSize="size_20" fontWeight="bold">
            ログイン
          </Paragraph>
          <Paragraph fontSize="size_13" color="content_secondary">
            アカウントをお持ちの方
          </Paragraph>
          <VStack marginTop="8px" gap="16px">
            <AuthButton
              title="Googleでログイン"
              icon={googleIcon}
              url={routes.provider_authentication.login.url(
                Providers.GOOGLE,
                from,
                fromService,
                colorMode
              )}
            />
            <AuthButton
              title="Appleでログイン"
              icon={appleIcon}
              url={routes.provider_authentication.login.url(
                Providers.APPLE,
                from,
                fromService,
                colorMode
              )}
            />
          </VStack>
          <VStack width="100%" maxWidth="360px" marginTop="28px">
            <Box width="100%" borderBottom="1px solid #D7D8DA">
              <Box
                position="relative"
                top="10px"
                margin="auto"
                backgroundColor="surface_primary"
                height="auto"
                width="65px"
                display="block"
                border="1px solid #FAFBFC"
                color="content_secondary"
                fontSize="size_12"
              >
                または
              </Box>
            </Box>
            <Box
              marginTop="36px"
              width="100%"
              maxWidth={{ base: '400px', smOnly: '100%' }}
            >
              <Suspense fallback={null}>
                <EmailFieldContainer fieldTarget="login" />
              </Suspense>
            </Box>
            <VStack marginTop="16px" color="content_secondary" gap="4px">
              <HStack>
                <Link href={`${routes.sms_login.url(from)}`}>
                  電話番号(SMS)でログイン
                </Link>
              </HStack>
              <Link
                href={routes.provider_authentication.login.url(
                  Providers.TWITTER,
                  from,
                  fromService,
                  colorMode
                )}
              >
                X（旧Twitter）でログイン
              </Link>
            </VStack>
          </VStack>

          <Box
            width="100%"
            position="absolute"
            bottom="18px"
            color="content_secondary"
            fontSize="size_12"
          >
            <styled.a color="tint" href={routes.tos.url()}>
              利用規約
            </styled.a>
            と
            <styled.a color="tint" href={routes.privacy.url()}>
              プライバシーポリシー
            </styled.a>
            <br />
            に同意いただける場合はログイン／会員登録へお進みください。
          </Box>
        </VStack>
      )}
    </Center>
  );
}
